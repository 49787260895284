import React from "react";

function Header({ onToggleSidebar }) {
  return (
    <div
      className="sidebar-left"
      onClick={onToggleSidebar}
      style={{
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        backgroundColor: "#333",
        padding: "10px",
        color: "#fff",
        zIndex: 1100, // Ensure the header is above the sidebar
        cursor: "pointer",
      }}
    >
      <a
        className="navbar-brand imglogo"
        href="dashboards"
        style={{ textDecoration: "none", color: "#fff" }}
      >
        {/* Account */}
      </a>
      <button
        className="btn btn-link icon-header mr-sm-2 pull-right menu-collapse"
        style={{
          background: "none",
          border: "none",
          color: "#fff",
          fontSize: "18px",
        }}
      >
        <span className="fa fa-bars" />
      </button>
    </div>
  );
}

export default Header;


{/* <div className="sidebar-left">
<a className="navbar-brand imglogo" href="dashboards" />
<button className="btn btn-link icon-header mr-sm-2 pull-right menu-collapse">
  <span className="fa fa-bars" />
</button>
</div> */}