import React, { useEffect, useRef, useState, useContext } from "react";
import { Helmet } from "react-helmet";
import Navbar from "./Dashnav/Navbar";
import Header from "./Dashnav/Header";
import { Link, Route, useHistory } from "react-router-dom";
import { f, database, storage, auth } from "../config";
import db from "../config";
import { GlobalContext } from "../Globalstate";
import firebase from "firebase";
import Swal from "sweetalert2";
import emailjs from "emailjs-com";
// import Header from "./Dashnav/Header";
// import { useNavigate } from "react-router-dom";

function Deposit() {

  const history = useHistory();
  const [dataready, setdataready] = useState(false);
  const [loading, setLoading] = useState(true);
  const [{ userdetails, loggedin, tradingpair }, dispatch] =
    useContext(GlobalContext);

  const [isSidebarVisible, setSidebarVisible] = useState(false);
const [amount, setamount] = useState(0)
  const toggleSidebar = () => {
    setSidebarVisible(!isSidebarVisible);
  };

  const navigate = useHistory();

  const handleDepositClick = () => {
    navigate.push(`/depositacc?amount=${amount}`); // Pass amount as a query parameter
  };

  useEffect(() => {
    if (loggedin) {
      console.log(userdetails);
      console.log(userdetails.email);
      setLoading(false);
    } else {
      f.auth().onAuthStateChanged(function (user) {
        if (user) {
          var userid = f.auth().currentUser;
          var userids = userid.uid;
          fetchuserdata(userids);
          setloggedin(true);
        } else {
          setloggedin(false);
          setLoading(false);
          history("/");
        }
      });
    }
  }, []);

  const fetchuserdata = async (userid) => {
    var docRef = db.collection("users").doc(userid);
    const fetching = await docRef
      .get()
      .then(function (doc) {
        if (doc.exists) {
          setdetails(doc.data());
          console.log(doc.data());
          setLoading(false);
          setdataready(true);
        } else {
          console.log("No such document!");
          setLoading(false);
        }
      })
      .catch(function (error) {
        console.log("Error getting document:", error);
      });
  };

  const setdetails = (data) => {
    dispatch({ type: "setuserdetails", snippet: data });
  };

  const setloggedin = (data) => {
    dispatch({ type: "setloggedin", snippet: data });
  };

  const logout = async () => {
    const let1 = await setloggedin(false);
    const let2 = await f.auth().signOut();
    global.window && (global.window.location.href = "/home.html");
    const let3 = await history.push("/");
  };

  return (
    <div>
      <meta name="ROBOTS" content="NOINDEX, NOFOLLOW" />
      <meta charSet="utf-8" />
      <meta
        name="viewport"
        content="width=device-width, initial-scale=0.75, shrink-to-fit=no"
      />
      <meta name="description" content />
      <meta name="author" content />
      <link rel="shortcut icon" href="img/favicon.png" type="image/x-icon" />
      {/* <title>TradingFxCapital | Dashboard</title> */}
      <link
        rel="stylesheet"
        href="vendor/font-awesome-4.7.0/css/font-awesome.min.css"
        type="text/css"
      />
      <link
        rel="stylesheet"
        href="vendor/bootstrap-4.1.1/css/bootstrap.css"
        type="text/css"
      />
      <link
        href="vendor/datatables/css/dataTables.bootstrap4.css"
        rel="stylesheet"
      />
      <link
        href="vendor/datatables/css/responsive.dataTables.min.css"
        rel="stylesheet"
      />
      <link
        href="vendor/jquery-jvectormap/jquery-jvectormap-2.0.3.css"
        rel="stylesheet"
      />
      <link rel="stylesheet" href="css/dark_grey_adminux.css" type="text/css" />
      <link
        rel="stylesheet"
        href="https://cdnjs.cloudflare.com/ajax/libs/animate.css/4.1.1/animate.min.css"
      />
      {/* g-hide */}
      <style
        type="text/css"
        dangerouslySetInnerHTML={{
          __html:
            "\n    iframe.goog-te-banner-frame {\n      display: none !important;\n    }\n  ",
        }}
      />
      <style
        type="text/css"
        dangerouslySetInnerHTML={{
          __html:
            "\n    iframe.skiptranslate {\n      display: none !important;\n    }\n  ",
        }}
      />
      <style
        type="text/css"
        dangerouslySetInnerHTML={{
          __html:
            "\n    body {\n      position: static !important;\n      top: 0px !important;\n    }\n  ",
        }}
      />
      {/* end-g-hide */}
      <style
        dangerouslySetInnerHTML={{
          __html:
            "\n    div.alert {\n      padding: 4px 16px;\n      background: white;\n    }\n\n    input:text {\n      color: white;\n    }\n  ",
        }}
      />
      {/* /GetButton.io widget*/}
      {/* /GetButton.io widget */}
      <style
        dangerouslySetInnerHTML={{
          __html:
            "\n    div.scrollmenu {\n      background-color: #333;\n      overflow: auto;\n      white-space: nowrap;\n    }\n\n    div.scrollmenu a {\n      display: inline-block;\n      color: white;\n      text-align: center;\n      padding: 14px;\n      text-decoration: none;\n    }\n\n    div.scrollmenu a:hover {\n      background-color: #777;\n    }\n\n    table,\n    td {\n      background: black;\n      color: white;\n    }\n\n    th {\n      background: #00000088;\n      color: white;\n    }\n  ",
        }}
      />
      <header className="navbar-fixed">
        <nav className="navbar navbar-toggleable-md navbar-inverse bg-faded">
          {/* Header can be reused across pages */}
          <Header onToggleSidebar={toggleSidebar} />
          {/* Sidebar */}
          <Navbar isVisible={isSidebarVisible} activePage={"Deposit"} />
          <div className="d-flex mr-auto"> &nbsp;</div>
          <ul className="navbar-nav content-right">
            <li className="align-self-center">
              {/* hidden-md-down */}
              <span>Lang:</span>
              <select
                className="custom-select lang-sel"
                style={{ width: "110px", border: "0px solid #0080db00" }}
                id="language"
                onchange="changeLanguageByButtonClick()"
              >
                <option value="en" selected>
                  English
                </option>
                <option value="af">Afrikanns</option>
                <option value="sq">Albanian</option>
                <option value="ar">Arabic</option>
                <option value="hy">Armenian</option>
                <option value="eu">Basque</option>
                <option value="bn">Bengali</option>
                <option value="bg">Bulgarian</option>
                <option value="ca">Catalan</option>
                <option value="km">Cambodian</option>
                <option value="zh-CN">Chinese (Mandarin)</option>
                <option value="hr">Croation</option>
                <option value="cs">Czech</option>
                <option value="da">Danish</option>
                <option value="nl">Dutch</option>
                <option value="et">Estonian</option>
                <option value="fj">Fiji</option>
                <option value="fi">Finnish</option>
                <option value="fr">French</option>
                <option value="ka">Georgian</option>
                <option value="de">German</option>
                <option value="el">Greek</option>
                <option value="gu">Gujarati</option>
                <option value="he">Hebrew</option>
                <option value="hi">Hindi</option>
                <option value="hu">Hungarian</option>
                <option value="is">Icelandic</option>
                <option value="id">Indonesian</option>
                <option value="ga">Irish</option>
                <option value="it">Italian</option>
                <option value="ja">Japanese</option>
                <option value="jw">Javanese</option>
                <option value="ko">Korean</option>
                <option value="la">Latin</option>
                <option value="lv">Latvian</option>
                <option value="lt">Lithuanian</option>
                <option value="mk">Macedonian</option>
                <option value="ms">Malay</option>
                <option value="ml">Malayalam</option>
                <option value="mt">Maltese</option>
                <option value="mi">Maori</option>
                <option value="mr">Marathi</option>
                <option value="mn">Mongolian</option>
                <option value="ne">Nepali</option>
                <option value="no">Norwegian</option>
                <option value="fa">Persian</option>
                <option value="pl">Polish</option>
                <option value="pt">Portuguese</option>
                <option value="pa">Punjabi</option>
                <option value="qu">Quechua</option>
                <option value="ro">Romanian</option>
                <option value="ru">Russian</option>
                <option value="sm">Samoan</option>
                <option value="sr">Serbian</option>
                <option value="sk">Slovak</option>
                <option value="sl">Slovenian</option>
                <option value="es">Spanish</option>
                <option value="sw">Swahili</option>
                <option value="sv">Swedish </option>
                <option value="ta">Tamil</option>
                <option value="tt">Tatar</option>
                <option value="te">Telugu</option>
                <option value="th">Thai</option>
                <option value="bo">Tibetan</option>
                <option value="to">Tonga</option>
                <option value="tr">Turkish</option>
                <option value="uk">Ukranian</option>
                <option value="ur">Urdu</option>
                <option value="uz">Uzbek</option>
                <option value="vi">Vietnamese</option>
                <option value="cy">Welsh</option>
                <option value="xh">Xhosa</option>
              </select>
            </li>
            <li className="v-devider" />
            <li className="nav-item"></li>
            <li className="v-devider" />
            <li className="nav-item ">
              {" "}
              <a
                className="btn btn-link icon-header menu-collapse-right"
                href="#"
              >
                <span className="fa fa-podcast" />{" "}
              </a>{" "}
            </li>
          </ul>
          <div className="sidebar-right pull-right ">
            <ul className="navbar-nav  justify-content-end">
              <li className="nav-item">
                <button
                  className="btn-link btn userprofile"
                  data-toggle="dropdown"
                  aria-haspopup="true"
                  aria-expanded="false"
                />
                <span className="userpic">
                  <img src="https://img.icons8.com/?size=80&id=108652&format=png" alt="user pic" />
                </span>{" "}
                <span className="text">offic</span>
              </li>
              <li>
                 <a onClick={logout}className="btn btn-link icon-header">
                  <span className="fa fa-power-off" />
                </a>
              </li>
            </ul>
          </div>
        </nav>
      </header>
      <div className="wrapper-content">
        <div className="container" style={{ maxWidth: "1300px" }}>
          {/* style="max-width:1400px" */}
          {/*<br><br> */}
          {/* <br> */}
          <title>TradingFxCapital | Deposit</title>
          <h1 style={{ textAlign: "center" }}>Make Deposit</h1>
          <div className="row">
            <div className="col-sm-16 col-md-8">
              <div className="card">
                <div className="card-header align-items-start justify-content-between flex">
                  <h4 className="pull-left">
                    Deposit Using Cryptocurrency
                  </h4>
                  <ul className="nav nav-pills card-header-pills pull-right">
                    <li className="nav-item">
                      <button
                        className="btn btn-sm btn-link btn-round"
                        data-toggle="collapse"
                        data-target="#btc_deposit"
                      >
                        <i className="fa fa-chevron-down" />
                      </button>
                    </li>
                  </ul>
                </div>
                <div className="card-body" id="btc_deposit">
                  <div className="ncard small">
                    <h6>Cryptocurrency Deposit Method</h6>
                    <hr />
                    <b>
                      <span style={{ color: "gold" }}>
                        {" "}
                        Please make sure you upload your payment proof for quick
                        payment verification
                      </span>
                      <br />
                      <span style={{ color: "#17a2b8 !important" }}>
                        On confirmation, our system will automatically Fund your
                        account and notify you via email address.
                      </span>
                    </b>
                    <br />
                    <form>
                      <div className="form-group row">
                        <label
                          htmlFor="exa1"
                          className="col-16 col-form-label"
                          style={{ textAlign: "left" }}
                        >
                          Amount <font color="crison">$ *</font>
                        </label>
                        <div className="col-16">
                          <input
                            className="form-control"
                            type="number"
                            step="any"
                            defaultValue
                            placeholder={0.0}
                            name="invest"
                            min={10}
                            id="exa1"
                            onChange={(e)=>{setamount(e.target.value)}}
                            required
                          />
                          <input
                            type="hidden"
                            defaultValue="Deposit"
                            name="action"
                            required
                          />
                        </div>
                      </div>
                      <div className="form-group row">
                        <div className="col-16">
                          <input
                            className="btn btn-primary"
                            style={{ float: "right" }}
                            name="investment"
                            defaultValue="Make Deposit"
                            onClick={() => handleDepositClick()} // Replace 1000 with the dynamic amount
                          />
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
            {/* <div className="col-sm-16 col-md-8">
              <div className="card">
                <div className="card-header align-items-start justify-content-between flex">
                  <h4 className="pull-left">Other Deposit Options</h4>
                  <ul className="nav nav-pills card-header-pills pull-right">
                    <li className="nav-item">
                      <button
                        className="btn btn-sm btn-link btn-round"
                        data-toggle="collapse"
                        data-target="#order_depo"
                      >
                        <i className="fa fa-chevron-down" />
                      </button>
                    </li>
                  </ul>
                </div>
                <div className="card-body" id="order_depo">
                  <div className="ncard small">
                    <h6>Request other available Deposit Method</h6>
                    <hr />
                    <b>
                      <span style={{ color: "gold" }}>
                        Once requested, you will receive the payment details via
                        our support mail....
                      </span>
                      <br />
                      <span style={{ color: "#17a2b8 !important" }}>
                        {" "}
                        Once payment is made using this method you are to send
                        your payment proof to our support mail{" "}
                        <a href="mailto:support@tradingdirectexpress.com">
                          support@tradingdirectexpress.com
                        </a>
                      </span>
                    </b>
                    <br />
                    <form>
                      <input
                        className="form-control"
                        type="hidden"
                        defaultValue="officialmarvez"
                        name="name"
                        id="exam2"
                        required
                      />
                      <input
                        className="form-control"
                        type="hidden"
                        defaultValue="Dillionronda@gmail.con"
                        name="email"
                        id="exam2"
                        required
                      />
                      <div className="form-group row">
                        <label
                          htmlFor="exam4"
                          className="col-16 col-form-label"
                          style={{ textAlign: "left" }}
                        >
                          Payment Option For<font color="crimson">*</font>
                        </label>
                        <div className="col-16">
                          <select
                            className="form-control"
                            value
                            name="deposit_type"
                            id="exam4"
                            required
                          >
                            <option disabled value selected>
                              --Select Payment Option --
                            </option>
                            <option value="BANK TRANSFER">BANK TRANSFER</option>
                            <option value="CASH DELIVERY">CASH DELIVERY</option>
                            <option value="GCASH">GCASH</option>
                            <option value="LITCOIN">LITCOIN</option>
                            <option value="BITCOIN CASH">BITCOIN CASH</option>
                            <option value="ETHEREUM">ETHEREUM</option>
                            <option value="PAYPAL">PAYPAL</option>
                            <option value="STELLER">STELLER</option>
                            <option value="WESTERN UNION">WESTERN UNION</option>
                            <option value="SKRILL">SKRILL</option>
                            <option value="MONEY GRAM">MONEY GRAM</option>
                          </select>
                        </div>
                      </div>
                      <div className="form-group row">
                        <div className="col-16">
                          <input
                            className="btn btn-primary"
                            style={{ float: "right" }}
                            name="request_deposit"
                            defaultValue="Request"
                            onClick={handleDepositClick}
                          />
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div> */}
            <a href="dashboards">
              <button className="btn btn-dark" style={{ color: "white" }}>
                <i className="fa fa-undo" /> Back to Account
              </button>
            </a>
          </div>
          <br />
          <br />
          <div className="row">
            <div className="col-md-12">
              <div className="form-group row">
                <div className="col-6">
                  <button
                    type="button"
                                       onClick={() => {
                      const walletAddress =
                        document.getElementById("referral_link").value;
                      navigator.clipboard
                        .writeText(walletAddress)
                        .then(() => {
                          Swal.fire({
                            icon: "success",
                            title: "Copied!",
                            text: "referral link copied to clipboard!",
                            timer: 2000,
                            showConfirmButton: false,
                          });
                        })
                        .catch(() => {
                          Swal.fire({
                            icon: "error",
                            title: "Error",
                            text: "Failed to copy referral link.",
                            timer: 2000,
                            showConfirmButton: false,
                          });
                        });
                    }}
                    className="btn btn-primary"
                  >
                    Copy Referral Link
                  </button>
                </div>
                <div className="col-10">
                  <input
                    type="text"
                    style={{ color: "black" }}
                    className="form-control"
                    id="referral_link"
                    defaultValue={`https://www.finance-tellars.com/register.html?${userdetails.myReferralId}`}
                    readOnly
                  />
                </div>
              </div>
            </div>
            {/* TradingView Widget BEGIN */}
            <div className="tradingview-widget-container">
              <div className="tradingview-widget-container__widget" />
            </div>
            {/* TradingView Widget END */}
            <style
              dangerouslySetInnerHTML={{
                __html:
                  "\n.mgm {\n    border-radius: 7px;\n    position: fixed;\n    z-index: 90;\n    bottom: 120px;\n    right: 20px;\n    background: #fff;\n    border:4px solid #3f48cc;\n    padding: 10px 27px;\n    box-shadow: 0px 5px 13px 0px rgba(0, 0, 0, .3);\n}\n\n.mgm a {\n    font-weight: 700;\n    display: block;\n    color: #3f48cc;\n}\n\n.mgm a,\n.mgm a:active {\n    transition: all .2s ease;\n    color: #3f48cc;\n}\n",
              }}
            />
            <div className="mgm" style={{ display: "none" }}>
              <div className="txt" style={{ color: "black" }} />
            </div>
          </div>
        </div>
      </div>
      <div
        id="google_translate_element"
        style={{ visibility: "visible", display: "none" }}
      />
      {/* TradingView Widget BEGIN */}
      <div className="sidebar-right tradingview-widget-container ">
        <iframe
          allowTransparency="true"
          style={{ boxSizing: "border-box", height: "100%", width: "100%" }}
          src="https://www.tradingview-widget.com/embed-widget/screener/?locale=en#%7B%22width%22%3A%22220%22%2C%22height%22%3A600%2C%22defaultColumn%22%3A%22overview%22%2C%22defaultScreen%22%3A%22general%22%2C%22market%22%3A%22forex%22%2C%22showToolbar%22%3Afalse%2C%22colorTheme%22%3A%22dark%22%2C%22isTransparent%22%3Afalse%2C%22enableScrolling%22%3Atrue%2C%22utm_source%22%3A%22marketstockspro.com%22%2C%22utm_medium%22%3A%22widget%22%2C%22utm_campaign%22%3A%22forexscreener%22%7D"
          frameBorder={0}
        />
        <style
          dangerouslySetInnerHTML={{
            __html:
              "\n    .tradingview-widget-copyright {\n      font-size: 13px !important;\n      line-height: 32px !important;\n      text-align: center !important;\n      vertical-align: middle !important;\n      font-family: 'Trebuchet MS', Arial, sans-serif !important;\n      color: #9db2bd !important;\n    }\n\n    .tradingview-widget-copyright .blue-text {\n      color: #2962FF !important;\n    }\n\n    .tradingview-widget-copyright a {\n      text-decoration: none !important;\n      color: #9db2bd !important;\n    }\n\n    .tradingview-widget-copyright a:visited {\n      color: #9db2bd !important;\n    }\n\n    .tradingview-widget-copyright a:hover .blue-text {\n      color: #1E53E5 !important;\n    }\n\n    .tradingview-widget-copyright a:active .blue-text {\n      color: #1848CC !important;\n    }\n\n    .tradingview-widget-copyright a:visited .blue-text {\n      color: #2962FF !important;\n    }\n  ",
          }}
        />
      </div>
      {/* TradingView Widget END */}
    </div>
  );
}

export default Deposit;
