// utils/investmentUtils.js

export   const investments = [
    {
      id: "1",
      name: "Rudiments Plan",
      minimum: "100",
      maximum: "499",
      Maximum:"499",
      profits: "3",
      duration: "31",
      description: "Capital returns on maturity, 24/7 support",
    },
    {
      id: "2",
      name: "Gold Plan",
      minimum: "500",
      maximum: "999",
      Maximum:"999",
      profits: "3.9",
      duration: "31",
      description: "Capital returns on maturity, 24/7 support",
    },
    {
      id: "3",
      name: "Antminer Plan",
      minimum: "1000",
      maximum: "2999",
      Maximum:"2999",
      profits: "4",
      duration: "30",
      description: "Capital returns on maturity, 24/7 support",
    },
    {
      id: "4",
      name: "Antminer Pro Plan",
      minimum: "3000",
      maximum: "4999",
      Maximum:"4999",
      profits: "4.5",
      duration: "30",
      description: "Capital returns on maturity, 24/7 support",
    },
    {
      id: "5",
      name: "Innosilicon Plan",
      minimum: "5000",
      maximum: "7999",
      Maximum:"7999",
      profits: "5",
      duration: "30",
      description: "Capital returns on maturity, 24/7 support",
    },
    {
      id: "6",
      name: "Variable Plan",
      minimum: "8000",
      maximum: "11999",
      Maximum:"11999",
      profits: "8",
      duration: "30",
      description: "Capital returns on maturity, 24/7 support",
    },
    {
      id: "7",
      name: "Variable Pro Plan",
      minimum: "12000",
      maximum: "14999",
      Maximum:"14999",
      profits: "8.9",
      duration: "30",
      description: "Capital returns on maturity, 24/7 support",
    },
    {
      id: "8",
      name: "VIP Plan",
      minimum: "15000",
      Maximum: "100000000",
      maximum: "unlimited",
      profits: "10",
      duration: "25",
      description: "Capital returns on maturity, 24/7 support",
    },
    {
      id: "9",
      name: "VIP Pro Plan",
      minimum: "22000",
      Maximum: "100000000",
      maximum: "unlimited",
      profits: "12.5",
      duration: "25",
      description: "Capital returns on maturity, 24/7 support",
    },
    {
      id: "10",
      name: "Shareholder Plan",
      minimum: "100000",
      Maximum: "100000000",
      maximum: "unlimited",
      profits: "30",
      duration: "35",
      description: "Capital returns on maturity, 24/7 support",
    },
    {
      id: "11",
      name: "Shareholder Pro Plan",
      minimum: "200000",
      Maximum: "100000000",
      maximum: "unlimited",
      profits: "50",
      duration: "45",
      description: "Capital returns on maturity, 24/7 support",
    },
  ];