import React, { useContext, useState, useEffect, useRef } from "react";
import { Link, Route, useHistory } from "react-router-dom";

function AppContainer() {

  const navigate = useHistory();

  useEffect(() => {
    // navigate("/example");
    global.window && (global.window.location.href = '/home.html')
  }, []);

  return (
    <div>


    </div>
  );
}

export default AppContainer;






// import React, { useContext, useState, useEffect, useRef } from "react";
// import { Link, Route, useHistory } from "react-router-dom";
// import Footer from "./components/Footer.js/Footer";
// import TradingView from "./components/Tradingview/Tradingview";
// import Aos from "aos";
// import { FaQuoteRight } from "react-icons/fa";
// import video from "./img/video.mp4"
// import {
//   ScrollingProvider,
//   useScrollSection,
//   Section,
// } from 'react-scroll-section';
// import "aos/dist/aos.css";
// import {
//   FaCoins,
//   FaCreditCard,
//   FaSeedling,
//   FaArrowsAltV,
//   FaBitcoin,
// } from "react-icons/fa";
// import { f, database, storage, auth } from "./config";
// import db from "./config";
// import { GlobalContext } from "./Globalstate";
// import "./App.css";
// import Nav from "./components/Nav/Nav";
// import { makeStyles } from "@material-ui/core/styles";
// import { Chart } from "react-google-charts";
// import Swiper from "swiper";

// const useStyles = makeStyles((theme) => ({
//   root: {
//     width: "100%",
//   },
//   heading: {
//     fontSize: theme.typography.pxToRem(15),
//     fontWeight: theme.typography.fontWeightRegular,
//   },
// }));

// function AppContainer() {
//   const classes = useStyles();
//   useEffect(() => {
//     Aos.init({ duration: 1000 });
//   }, []);
//   const [{ userdetails, loggedin }, dispatch] = useContext(GlobalContext);
//   const [auto, setauto] = useState(true);
//   const slides = document.querySelectorAll(".slide");
//   const next = document.querySelector("#next");
//   const prev = document.querySelector("#prev");
//   const intervalTime = 10000;

//   const swiper = useRef(null);
//   useEffect(() => {
//     swiper.current = new Swiper(".swiper-container", {
//       effect: "coverflow",
//       grabCursor: true,
//       centeredSlides: true,
//       slidesPerView: "auto",
//       coverflowEffect: {
//         rotate: 0,
//         stretch: 0,
//         depth: 100,
//         modifier: 2,
//         slideShadows: true,
//       },
//       loop: true,
//     });
//   }, []);

//   const setdetails = (data) => {
//     dispatch({ type: "setuserdetails", snippet: data });
//   };

//   const setloggedin = (data) => {
//     dispatch({ type: "setloggedin", snippet: data });
//   };
//   const nextSlide = () => {
//     console.log("gghghdjgjd");
//     // Get current class
//     const current = document.querySelector(".current");
//     // Remove current class
//     current.classList.remove("current");
//     // Check for next slide
//     if (current.nextElementSibling) {
//       // Add current to next sibling
//       current.nextElementSibling.classList.add("current");
//     } else {
//       // Add current to start
//       const slide = document.querySelectorAll(".slide");
//       slide[0].classList.add("current");
//     }
//     setTimeout(() => current.classList.remove("current"));
//   };

//   const prevSlide = () => {
//     console.log("gghghdjgjd");
//     // Get current class
//     const current = document.querySelector(".current");
//     // Remove current class
//     current.classList.remove("current");
//     // Check for prev slide
//     if (current.previousElementSibling) {
//       // Add current to prev sibling
//       current.previousElementSibling.classList.add("current");
//     } else {
//       // Add current to last
//       const slide = document.querySelectorAll(".slide");
//       slide[slide.length - 1].classList.add("current");
//     }
//     setTimeout(() => current.classList.remove("current"));
//   };

//   return (
//     <ScrollingProvider>
//       <div>
//         <Nav />
//         <div className="hero__section">
//           <video src={video} autoPlay loop muted playsInline="true" disablePictureInPicture="true" class="fullscreen-bg__video" />
//           <div className="child__text1">
//             <div className="hero__section__child">
//               <h1 className="gold__color__h1">JOIN US</h1>
//               <h1>AND START MAKING PROFIT TODAY</h1>
//               <p>
//                 Digital from the core. Now you can earn profit at a go from our
//                 automated trade and mining services. We deliver game changing
//                 efficiencies in finance and create a transparent and intelligent
//                 investment administration
//           </p>
//             </div>
//             {/* <img src="/img/banner-img.svg" /> */}
//           </div>
//         </div>
//         <div className="reg__btns__section">
//           <Link to="/register" className="form-input-btn">Login</Link>
//           <Link to="/Separatereg" className="form-input-btn">Register</Link>
//         </div>

//         <div className="second__section">
//           <div>
//             <h3>How To Earn Through our Network Power Provider</h3>
//             <div className="how__to__wrapper">
//               <div data-aos="zoom-in" className="how__content">
//                 <FaCreditCard className="invest__icons" />
//                 <p>Fund Account</p>
//                 <p>Deposit into account through the available channels</p>
//               </div>

//               <div data-aos="zoom-in" className="how__content">
//                 <FaSeedling className="invest__icons" />
//                 <p>Invest Fund</p>
//                 <p>
//                   Choose deisred investment plan and invest already deposited
//                   funds
//               </p>
//               </div>

//               <div data-aos="zoom-in" className="how__content">
//                 <FaCoins className="invest__icons" />
//                 <p>Receive Profit</p>
//                 <p>
//                   Profits are guaranteed and would be deposited daily into
//                   investor account.
//               </p>
//               </div>
//             </div>
//             {/* <p>
//             Bitcoin is a Decentralized Network and no body can control. Miners
//             are process bitcoin transactions and getting fee to processing.
//             Mining can start on 3 easy step ways
//           </p>
//           <p>Make a Bitcoin Wallet On Blockchain.com</p>
//           <p>Make An Account On Here And Get a Miner</p>
//           <p>Do on your account and Collect on your blockchain Wallet</p> */}
//           </div>
//           <img src="/img/about-img.svg" data-aos="zoom-in" />
//         </div>

//         <div className="reg__btns__section1">
//           <Link to="/pricing" className="form-input-btn">View Investment plans</Link>
//         </div>


//         <Section id="home">

//           <div className="why__us__section1">
//             <h1>WHY US</h1>
//             <div className="why__us__section">
//               <div className="why__us__child" data-aos="zoom-in">
//                 <img src="/img/services-icon1.svg" />
//                 <h2>Multi Currencies</h2>
//                 <p>
//                   we Are Supporting BTC, ETH, Lite Coin, Bitcoin Cash As a Payment
//             </p>
//               </div>

//               <div className="why__us__child" data-aos="zoom-in">
//                 <img src="/img/services-icon2.svg" />
//                 <h2>Stable Plans</h2>
//                 <p>Powers uptime 99.1 Guaranteed. Miners Can Mining for full day</p>
//               </div>

//               <div className="why__us__child" data-aos="zoom-in">
//                 <img src="/img/services-icon3.svg" />
//                 <h2>2FA Secure Login</h2>
//                 <p>
//                   Any Accounts can setup Mail Confirmation 2FA login to make more
//                   account security
//             </p>
//               </div>

//               <div className="why__us__child" data-aos="zoom-in">
//                 <img src="/img/services-icon4.svg" />
//                 <h2>Reliable & Low Cost</h2>
//                 <p>
//                   Good Strategy can make more effective profit. Managed Mining
//                   Servers can make enough power to mining on accounts
//             </p>
//               </div>

//               <div className="why__us__child" data-aos="zoom-in">
//                 <img src="/img/services-icon5.svg" />
//                 <h2>Stable Transaction Network</h2>
//                 <p>
//                   Platform’s Ai Watching on network and processing automatic and
//                   instant transactions
//             </p>
//               </div>

//               <div className="why__us__child" data-aos="zoom-in">
//                 <img src="/img/services-icon6.svg" />
//                 <h2>Experts Support</h2>
//                 <p>
//                   Friendly and Expert Supports are ready to help users. Support
//                   Ticket is open for all
//             </p>
//               </div>
//             </div>
//           </div>
//         </Section>

//         <div className="financial__ability" data-aos="zoom-in">
//           <div>
//             <h1>Financial Growth</h1>
//             <h2>Financial Growth status is updated daily</h2>
//             <p>
//               Financial Growth shows the success rate of the organisation. This
//               means the input and output volume of the financial transactions
//               processed by the company. the blue indicates the deposits rate and
//               the red indicates the withdrawal rate.
//           </p>
//             {/* <div className="deposit__indicator"></div><span>10% deposits</span>
//           <div className="withdrwals__indicator"></div><span>38% deposits</span> */}
//           </div>

//           <Chart
//             width={"400px"}
//             height={"500px"}
//             chartType="PieChart"
//             loader={<div>Loading Chart</div>}
//             data={[
//               ["Task", "Hours per Day"],
//               ["Deposit", 11],
//               ["Withdrawals", 2],
//             ]}
//             options={{
//               title: "Financial Growth",
//               pieHole: 0.4,
//             }}
//             rootProps={{ "data-testid": "1" }}
//           />
//         </div>
//         <TradingView />
//         {/* <div className="swiper-container">
//           <div className="swiper-wrapper">

//             <div className="swiper-slide">
//               <div className="testimonialbox">
//                 <FaQuoteRight className="quote" />
//                 <div className="testimonialbox__content">
//                   <p>
//                     Honestly I thought it was a scam until I got my withdrawal, the whole process was a little scary because of the charges which I must say, was really worth the stress.
//           </p>

//                   <div className="testimonialbox__content__details">
//                     <div className="testimonialbox__content__img">
//                       <img src="/img/cast1.jpg" />
//                     </div>
//                     <h3>
//                       Mario Hermiston
//               <br />
//                       <span> investor</span>{" "}
//                     </h3>
//                   </div>
//                 </div>
//               </div>
//             </div>




//             <div className="swiper-slide">
//               <div className="testimonialbox">
//                 <FaQuoteRight className="quote" />
//                 <div className="testimonialbox__content">
//                   <p>
//                     Do invest with this company. Everything about CHEEETAHINVESTMENT is not fake.
//           </p>

//                   <div className="testimonialbox__content__details">
//                     <div className="testimonialbox__content__img">
//                       <img src="/img/cast2.jpg" />
//                     </div>
//                     <h3>
//                       Maxie Johns
//               <br />
//                       <span> investor</span>{" "}
//                     </h3>
//                   </div>
//                 </div>
//               </div>

//             </div>


//             <div className="swiper-slide">
//               <div className="testimonialbox">
//                 <FaQuoteRight className="quote" />
//                 <div className="testimonialbox__content">
//                   <p>
//                     very reliable investment management company
//           </p>

//                   <div className="testimonialbox__content__details">
//                     <div className="testimonialbox__content__img">
//                       <img src="/img/cast3.jpg" />
//                     </div>
//                     <h3>
//                       Bulah Abbott
//               <br />
//                       <span> investor</span>{" "}
//                     </h3>
//                   </div>
//                 </div>
//               </div>

//             </div>





//             <div className="swiper-slide">
//               <div className="testimonialbox">
//                 <FaQuoteRight className="quote" />
//                 <div className="testimonialbox__content">
//                   <p>
//                     very reliable investment management company
//               </p>

//                   <div className="testimonialbox__content__details">
//                     <div className="testimonialbox__content__img">
//                       <img src="/img/cast4.jpg" />
//                     </div>
//                     <h3>
//                       Garth Kshlerin
//                   <br />
//                       <span> investor</span>{" "}
//                     </h3>
//                   </div>
//                 </div>
//               </div>
//             </div>





//             <div className="swiper-slide">
//               <div className="testimonialbox">
//                 <FaQuoteRight className="quote" />
//                 <div className="testimonialbox__content">
//                   <p>
//                     Got my payment before time. This website is something unique because every other site I have tried has not even been close to offer the same returns as they do. 100% legitimate and trustworthy.
//                   </p>

//                   <div className="testimonialbox__content__details">
//                     <div className="testimonialbox__content__img">
//                       <img src="/img/cast5.jpg" />
//                     </div>
//                     <h3>
//                       Christina Kozey
//                       <br />
//                       <span> investor</span>{" "}
//                     </h3>
//                   </div>
//                 </div>
//               </div>
//             </div>





//             <div className="swiper-slide">
//               <div className="testimonialbox">
//                 <FaQuoteRight className="quote" />
//                 <div className="testimonialbox__content">
//                   <p>
//                     There returns are good but delayed payouts i got my payout after 4 to 5days of request
//                       </p>

//                   <div className="testimonialbox__content__details">
//                     <div className="testimonialbox__content__img">
//                       <img src="/img/cast6.jpg" />
//                     </div>
//                     <h3>
//                       Jasen DuBuque
//                           <br />
//                       <span> investor</span>{" "}
//                     </h3>
//                   </div>
//                 </div>
//               </div>

//             </div>



//             <div className="swiper-slide">
//               <div className="testimonialbox">
//                 <FaQuoteRight className="quote" />
//                 <div className="testimonialbox__content">
//                   <p>
//                     Investing with cheetainvest has been a great experience. An exceptional group of expert fund managers
//                           </p>

//                   <div className="testimonialbox__content__details">
//                     <div className="testimonialbox__content__img">
//                       <img src="/img/cast7.jpg" />
//                     </div>
//                     <h3>
//                       Christina Kozey
//                               <br />
//                       <span> investor</span>{" "}
//                     </h3>
//                   </div>
//                 </div>
//               </div>


//             </div>


//             <div className="swiper-slide">
//               <div className="testimonialbox">
//                 <FaQuoteRight className="quote" />
//                 <div className="testimonialbox__content">
//                   <p>
//                     I can tell you they are amazing and I basically made good profits with them.
//                               </p>

//                   <div className="testimonialbox__content__details">
//                     <div className="testimonialbox__content__img">
//                       <img src="/img/cast8.jpg" />
//                     </div>
//                     <h3>
//                       Johnnie Torphy
//                                   <br />
//                       <span> investor</span>{" "}
//                     </h3>
//                   </div>
//                 </div>
//               </div>
//             </div>





//             <div className="swiper-slide">
//               <div className="testimonialbox">
//                 <FaQuoteRight className="quote" />
//                 <div className="testimonialbox__content">
//                   <p>
//                     There are the best in asset management, I’m glad they now have an official website, I made good profits with them.
//                                   </p>

//                   <div className="testimonialbox__content__details">
//                     <div className="testimonialbox__content__img">
//                       <img src="/img/cast9.jpg" />
//                     </div>
//                     <h3>
//                       Elroy Balistreri
//                                       <br />
//                       <span> investor</span>{" "}
//                     </h3>
//                   </div>
//                 </div>
//               </div>
//             </div>





//             <div className="swiper-slide">
//               <div className="testimonialbox">
//                 <FaQuoteRight className="quote" />
//                 <div className="testimonialbox__content">
//                   <p>
//                     dont"t call them scammers they are a good investment company
//                                       </p>

//                   <div className="testimonialbox__content__details">
//                     <div className="testimonialbox__content__img">
//                       <img src="/img/cast10.jpg" />
//                     </div>
//                     <h3>
//                       Rodolfo Effertz
//                                           <br />
//                       <span> investor</span>{" "}
//                     </h3>
//                   </div>
//                 </div>
//               </div>


//             </div>

//             <div className="swiper-slide">
//               <div className="testimonialbox">
//                 <FaQuoteRight className="quote" />
//                 <div className="testimonialbox__content">
//                   <p>
//                     I am impressed about their services. Their customer services
//                     are always here to help. Thanks to Cetofinance.
//                 </p>

//                   <div className="testimonialbox__content__details">
//                     <div className="testimonialbox__content__img">
//                       <img src="/img/user1.png" />
//                     </div>
//                     <h3>
//                       Kenna Adams
//                     <br />
//                       <span> investor</span>{" "}
//                     </h3>
//                   </div>
//                 </div>
//               </div>
//             </div>
//             <div className="swiper-slide">
//               <div className="testimonialbox">
//                 <FaQuoteRight className="quote" />
//                 <div className="testimonialbox__content">
//                   <p>
//                     Happy to purchase a plan from Cetofinance, Fast Delivery
//                     and at the best online price.
//                 </p>

//                   <div className="testimonialbox__content__details">
//                     <div className="testimonialbox__content__img">
//                       <img src="/img/user2.png" />
//                     </div>
//                     <h3>
//                       Sabrina Feeney
//                     <br />
//                       <span> investor</span>{" "}
//                     </h3>
//                   </div>
//                 </div>
//               </div>
//             </div>
//             <div className="swiper-slide">
//               <div className="testimonialbox">
//                 <FaQuoteRight className="quote" />
//                 <div className="testimonialbox__content">
//                   <p>
//                     Thanks for being consistent on your payment and i also
//                     appreciate your quick response whenever I send a mail. I’m
//                     lucky to be part of this wonderful “get rich platform” May
//                     allah bless you for your trustworthiness
//                 </p>

//                   <div className="testimonialbox__content__details">
//                     <div className="testimonialbox__content__img">
//                       <img src="/img/user3.png" />
//                     </div>
//                     <h3>
//                       Eli Gislason
//                     <br />
//                       <span> investor</span>{" "}
//                     </h3>
//                   </div>
//                 </div>
//               </div>
//             </div>
//             <div className="swiper-slide">
//               <div className="testimonialbox">
//                 <FaQuoteRight className="quote" />
//                 <div className="testimonialbox__content">
//                   <p>
//                     This is a realistic program for anyone looking for site to
//                     invest. Paid to me regularly, Good and quick profit. I used
//                     this website many times and I can recommend it. keep up good
//                     work.
//                 </p>

//                   <div className="testimonialbox__content__details">
//                     <div className="testimonialbox__content__img">
//                       <img src="/img/user4.png" />
//                     </div>
//                     <h3>
//                       Gregoria Swaniawski
//                     <br />
//                       <span> investor</span>{" "}
//                     </h3>
//                   </div>
//                 </div>
//               </div>
//             </div>
//             <div className="swiper-slide">
//               <div className="testimonialbox">
//                 <FaQuoteRight className="quote" />
//                 <div className="testimonialbox__content">
//                   <p>
//                     the best in class tradig service that is supported by our
//                     technologically superior trading strategy.
//                 </p>

//                   <div className="testimonialbox__content__details">
//                     <div className="testimonialbox__content__img">
//                       <img src="/img/user5.png" />
//                     </div>
//                     <h3>
//                       Erin Wolff
//                     <br />
//                       <span> investor</span>{" "}
//                     </h3>
//                   </div>
//                 </div>
//               </div>
//             </div>
//           </div>
//         </div> */}
//         <Footer />
//         <iframe
//           className="stick__price__footer"
//           src="https://widget.coinlib.io/widget?type=horizontal_v2&theme=light&pref_coin_id=1505&invert_hover=no"
//           width="100%"
//           height="45px"
//           scrolling="auto"
//           marginwidth="0"
//           marginheight="0"
//           frameborder="0"
//           border="0"
//         ></iframe>

//       </div>
//     </ScrollingProvider>
//   );
// }

// export default AppContainer;
