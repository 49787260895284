import React, { useContext, useState, useEffect ,} from "react";
// import Swiper from "swiper";
// import "swiper/swiper.min.css";
import "./App.css";
import AppContainer from "./AppContainer";
// import Register from "./components/Register";
// import Pricing from "./components/Pricing";
// import Faq from "./components/Faq";
// import Aboutus from "./components/Aboutus";
// import Markets from "./components/Markets";
// import Terms from "./components/Terms";
// import Privacy from "./components/Privacy";
// import Contact from "./components/Contact";
import { BrowserRouter, Link, Route, Switch} from "react-router-dom";
import { GlobalState } from "./Globalstate";
// import Forgotpass from "./components/Forgotpass";
// import Separatereg from "./components/Separatereg"
import Dashboard from "./Dashboard/Dashboard";
import Deposit from "./Dashboard/Deposit";
import Refferals from "./Dashboard/Refferals";
import Settings from "./Dashboard/Settings";
import Withdrawal from "./Dashboard/Withdrawal";
import History from "./Dashboard/History";
import Transactions from "./Dashboard/Transactions";
import SignalPurchase from "./Dashboard/SignalPurchase";
import AccountUpgrade from "./Dashboard/AccountUpgrade";
import DepositAccount from "./Dashboard/DepositAccount";
import DepositForSignal from "./Dashboard/DepositForSignal";
import InvestFromBalance from "./Dashboard/InvestfromBalance";
function App() {

  // { name: "Account", href: "dashbords", icon: "fa-home" },
  // { name: "Deposit", href: "deposit", icon: "fa-money" },
  // { name: "Withdraw", href: "withdraw", icon: "fa-credit-card" },
    // { name: "Settings", href: "settings", icon: "fa-cog" },
    
  // { name: "History", href: "history", icon: "fa-exchange" },
  // { name: "Transactions", href: "transaction", icon: "fa-exchange" },
  // { name: "Signal Purchase", href: "signal", icon: "fa-signal" },
  // { name: "Account Upgrade", href: "upgrade", icon: "fa-bank" },

  return (
    <>
      <GlobalState>
        <BrowserRouter>
          <Switch>
            <Route exact path="/" component={AppContainer} />
            <Route  path="/dashboards" component={Dashboard} />
            <Route  path="/deposit" component={Deposit} />
            <Route  path="/depositacc" component={DepositAccount} />
            <Route  path="/withdraw" component={Withdrawal} />
            <Route  path="/settings" component={Settings} />
            <Route path="/history" component={History} />
            <Route path="/transaction" component={Transactions}/>
            <Route path="/signal" component={InvestFromBalance}/>
            <Route path="/upgrade" component={AccountUpgrade}/>
            <Route path="/signaldeposit" component={DepositForSignal}/>
          </Switch>
        </BrowserRouter>
      </GlobalState>
    </>
  );
}

export default App;
